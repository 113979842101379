import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
import 'slick-carousel';

/**
 * Anonymous function is autoexecutable.
 */
(function () {

  let initialHash = location.hash;
  const $html = document.querySelector('html');

  function openModal(hash){
    closeModal();
    let $el = document.querySelector(hash);
    if ( $el && $el.classList.contains('modal') ) {
      $html.classList.add('overflow');
      $el.classList.add('active');
    }
  }

  function closeModal(){
    document.querySelectorAll('.modal').forEach($el => {
      $html.classList.remove('overflow');
      $el.classList.remove('active');
    });
  }

  function cleanHash(){
    history.pushState(null, null, '#');
  }

  window.addEventListener('hashchange', () => {
    let hash = window.location.hash;
    //console.log(hash);
    if ( hash !== '' ) {
      openModal(hash);
    } else {
      closeModal();
    }
  });


  if (initialHash.length > 0) {
    openModal(initialHash);
  }

  var $anchorLinks = document.querySelectorAll('a[href^="#"]');
  $anchorLinks.forEach(function (link) {
    if (link.hash.length > 0) {

        link.addEventListener('click', function (ev) {
          let container = document.getElementById(link.hash.substring(1));
          if(container.classList.contains('modal')){
            ev.stopPropagation();
            window.location.hash = link.hash  
          }
          
        });
    }

  });

  document.addEventListener('keydown', function(e) {
    if (e.which === 27) {
      closeModal();
      cleanHash();
    }
  });

  if($('.modal-close').length > 0){
    $('.modal-close').on('click', function(e){
      e.preventDefault;
      closeModal();
      cleanHash();
    });
  }

  window.onclick = function(event) {
    if(event.target.classList.contains('modal')){
      closeModal();
      cleanHash();
    }
  }

  $('.gallery_for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.gallery_nav'
  });
  
  $('.gallery_nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.gallery_for',
    dots: false,
    centerMode: true,
    focusOnSelect: true
  });

})();
